import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader";

const Category = ({
  categoryData,
  kidsData,
  onCategoryHandler,
  activeIndex,
}) => {
  return (
    <div className="col mb-3 text-center">
      <div className="categories">
        <button
          className="active"
          onClick={(e) => onCategoryHandler(e, null, "all")}
        >
          All Kids<span className="badge">{kidsData?.length}</span>
        </button>
        {categoryData.map((c, i) => (
          <button
            className={i === activeIndex ? "active" : ""}
            onClick={(e) => onCategoryHandler(e, c.id, i)}
          >
            {c.category_name}

            <span className="badge" style={{ background: c?.category_color }}>
              {kidsData.filter((kid) => kid.category_id == c.id).length}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Category;
