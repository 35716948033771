import HTTP from '../utils/HTTP';

class UserService {
	loginUser = data => {
		return HTTP.post('/user/login', { ...data });
	};
	superAdminLogin = data => {
		return HTTP.post('/user/login/super-admin', { ...data });
	};
	register = data => {
		return HTTP.post('/user/register', { ...data });
	};
	verifyOtp = data => {
		return HTTP.post('/user/verifyotp', data);
	};
	resendOtp = data => {
		return HTTP.post('/user/resendotp', data);
	};
	updateUser = data => {
		return HTTP.put('/user/update', { ...data });
	};
	fetchUser = ({ user_id, super_admin_id }) => {
		let fetchUserUrl = super_admin_id ? "/user/super-admin/id" : "/user/mentor/id" 
		return HTTP.get(fetchUserUrl,{
			params:{
				user_id,
				super_admin_id
			}
		})
	};
	
}

export default new UserService();
