import React, { useEffect, useState } from "react";
import Card from "./components/Card";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import KidService from "../../services/kids";
import ExpencesService from "../../services/expences";
import Loader from "../../components/Loader";

import moment from "moment";
import decodeToken from "../../utils/decodeToken";

const KidProfile = () => {
  const [profile, setProfile] = useState({});
  const [expences, setExpences] = useState([]);
  const [kidPhotos, setKidPhotos] = useState([]);
  const [kidDocuments, setKidDocuments] = useState([]);
  const [isImgFull, setIsImgFull] = useState("");
  const [loading, setLoading] = useState(true);

  const { user_id, super_admin_id } = decodeToken();
  let params = new URLSearchParams(window?.location?.search);
  const isSponsoredKid = params?.get("isSponsored");

  let { kid_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getKid();
    getKidExpences();
  }, []);

  useEffect(() => {
    if ((user_id || super_admin_id) && profile.enrollment_id) {
      getKidPhotos();
      getKidDocuments();
    }
  }, [profile.enrollment_id]);

  let getKid = async () => {
    try {
      const { data } = await KidService.fetchKidById(kid_id);
      setProfile(data.data[0]);
      setLoading(false);
    } catch (err) {}
  };

  let getKidExpences = async () => {
    try {
      const { data } = await ExpencesService.fetchExpencesKid(kid_id);
      setExpences(data.data);
    } catch (err) {}
  };

  let getKidPhotos = async () => {
    try {
      const { data } = await KidService.fetchPhotosByKidId({
        enrollment_id: profile?.enrollment_id,
      });
      setKidPhotos(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  let getKidDocuments = async () => {
    try {
      const { data } = await KidService.fetchDocumentsByKidId({
        enrollment_id: profile?.enrollment_id,
      });
      setKidDocuments(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container my-4 profile-wrapper">
          <div className="row">
            <div className="col-sm-9">
              <div className="card p-0">
                <div className="card-body p-0">
                  <div className="cover-img">
                    <img
                      src={profile.cover_url}
                      style={{ width: "100%" }}
                      alt="cover"
                    />
                  </div>
                  <div className="d-flex px-4 flex-wrap mt-3">
                    <div className="profile-img">
                      <img
                        src={profile.avatar_url || "../child/noImg.png"}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="kid-name-wraper">
                      <h3 className="text-capitalize">{profile.name}</h3>
                      <div className="unique-id">{profile.enrollment_id}</div>
                    </div>
                    <div className="mt-sm-3 sponsor-btn-wrapper">
                      {!isSponsoredKid && profile.total_expenses > 0 && (
                        <button
                          onClick={() =>
                            user_id
                              ? navigate(`/checkout/${kid_id}`)
                              : navigate(`/login`)
                          }
                          className="btn-primary me-3 mb-3"
                        >
                          {user_id ? "Sponsor" : "Sponsor by Registering"}
                        </button>
                      )}

                      {!user_id &&
                        !isSponsoredKid &&
                        profile.total_expenses > 0 && (
                          <button
                            onClick={() => navigate(`/checkout/${kid_id}`)}
                            className="btn-primary mb-3 "
                          >
                            Sponsor Anonymously
                          </button>
                        )}
                    </div>
                  </div>

                  <div className="d-flex px-4 details">
                    <div>
                      <i class="fa fa-cubes me-1" aria-hidden="true"></i>{" "}
                      {profile.category_name}
                    </div>
                  </div>
                  <div className="p-4 pt-0">
                    <Tabs>
                      <TabList>
                        <Tab>Profile</Tab>
                        <Tab>Expenses</Tab>
                        <Tab>Photo</Tab>
                        <Tab>Document</Tab>
                      </TabList>
                      <TabPanel>
                        <h6>
                          <b>Profile Info</b>
                        </h6>
                        <div className="rounded border px-3 py-2 mb-3">
                          <b>Monthly Expense for this Kid:</b>
                          <br />₹
                          {profile.total_expenses
                            ? profile.total_expenses
                            : profile.basic_amount}
                        </div>
                        <div className="d-flex">
                          <div className="w-100 rounded border px-3 py-2 mb-3">
                            <span className="bold">Born:</span>{" "}
                            <span className="dark">
                              {moment(profile.date_of_birth).format("YYYY")}
                            </span>{" "}
                          </div>
                          <div className="w-100 ms-3 rounded border px-3 py-2 mb-3">
                            <span className="bold">Category:</span>{" "}
                            <span className="dark">
                              {profile.category_name}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="w-100 rounded border px-3 py-2 mb-3">
                            <span className="bold">Lives in:</span>{" "}
                            <span className="dark">{profile.address}</span>
                          </div>
                          <div className="w-100 ms-3 rounded border px-3 py-2 mb-3">
                            <span className="bold">Bio:</span>
                            {""} <span className="dark">{profile.bio}</span>
                          </div>
                        </div>
                        {user_id || super_admin_id ? (
                          <div className="d-flex">
                            <div className="w-100 rounded border px-3 py-2 mb-3">
                              <span className="bold">Father's Name:</span>
                              {""}{" "}
                              <span className="dark">
                                {profile.father_name}
                              </span>
                            </div>
                            <div className="w-100 ms-3 rounded border px-3 py-2 mb-3">
                              <span className="bold">Mother's Name:</span>{" "}
                              <span className="dark">
                                {profile.mother_name}
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </TabPanel>
                      <TabPanel>
                        <h6>
                          <b>Kids Expenditure</b>
                        </h6>
                        <Tabs>
                          <TabList>
                            <Tab>Basic Expense</Tab>
                            <Tab>Educational Expense</Tab>
                            <Tab>Co-curricular Expense</Tab>
                            <Tab>Activity Expense</Tab>
                          </TabList>

                          <TabPanel>
                            <h5 class="bg-light p-3">
                              Basic Monthly Expense for this Kid: Rs ₹
                              {profile?.basic_amount}
                            </h5>
                          </TabPanel>
                          <TabPanel>
                            <div className="d-flex">
                              {expences?.education?.length > 0 &&
                                expences?.education.map((e) => (
                                  <div className="w-100 me-2 rounded border px-3 py-2 mb-3">
                                    {e.education_name}: ₹{e.amount}
                                  </div>
                                ))}
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="d-flex">
                              {expences?.curriculum?.length > 0 &&
                                expences?.curriculum.map((c) => (
                                  <div className="w-100  me-2 rounded border px-3 py-2 mb-3">
                                    {c.curriculum_name}: ₹{c.amount}
                                  </div>
                                ))}
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="d-flex">
                              {expences?.extra_curriculum?.length > 0 &&
                                expences?.extra_curriculum.map((extra) => (
                                  <div className="w-100  me-2 rounded border px-3 py-2 mb-3">
                                    {extra.extra_curriculum_name}: ₹
                                    {extra.amount}
                                  </div>
                                ))}
                            </div>
                          </TabPanel>
                        </Tabs>
                      </TabPanel>

                      <>
                        <TabPanel>
                          {(user_id && isSponsoredKid) || super_admin_id ? (
                            <div className="row">
                              {kidPhotos.length > 0 ? (
                                kidPhotos.map((k) => (
                                  <div className="col-sm-3">
                                    <img
                                      src={k.photo_url}
                                      className="img-fluid img-thumbnail"
                                      alt="kidimage"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => setIsImgFull(k.photo_url)}
                                    />
                                  </div>
                                ))
                              ) : (
                                <h5>No Photos</h5>
                              )}
                            </div>
                          ) : (
                            <h6 className="alert alert-danger my-3">
                              Please login as mentor
                            </h6>
                          )}
                        </TabPanel>
                        <TabPanel>
                          {(user_id && isSponsoredKid) || super_admin_id ? (
                            <div className="row">
                              {kidDocuments.length > 0 ? (
                                kidDocuments.map((k) => (
                                  <div className="col-sm-3">
                                    <i className="fa fa-file-o"></i>&nbsp;
                                    <a
                                      href={k.document_url}
                                      download
                                      target="blank"
                                      style={{ textDecoration: "none" }}
                                    >
                                      {k.title}
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <h5>No Documents</h5>
                              )}
                            </div>
                          ) : (
                            <h6 className="alert alert-danger my-3">
                              Please login as mentor
                            </h6>
                          )}
                        </TabPanel>
                      </>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card">
                <div className="card-body">
                  <h5>
                    <b>About</b>
                  </h5>
                  <h6 className="text-capitalize">{profile.name}</h6>
                  <p>
                    {" "}
                    <span className="text-muted">Born:</span>{" "}
                    {moment(profile.date_of_birth).format("YYYY")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {isImgFull && (
            <div className="img-overlay">
              <img src={isImgFull} className="img-fluid" />
              <i
                onClick={() => setIsImgFull("")}
                className="fa fa-close close-img"
              ></i>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default KidProfile;
