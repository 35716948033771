import { useState, useEffect } from "react";
import UserService from "../../services/user";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import decodeToken from "../../utils/decodeToken";
import { uploadImg, deleteImg } from "../../utils/uploadImg";

import "../../auth/register/register.css";
import "../../auth/login/login.css";
import convertBase64 from "../../utils/convertBase64";
import Backdrop from "../../components/Backdrop";

const MentorProfile = () => {
  const validationSchema = yup.object({
    full_name: yup.string("Enter fullname").required("Fullname is required"),
    mobile_no: yup
      .string("Enter Mobile number")
      .required("Mobile number is required"),
    password: yup.string("Enter Password").required("Password is required"),
  });

  const [rowData, setRowData] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [avtarImg, setAvtarImg] = useState();
  const [file, setFile] = useState();
  const [backdrop, setBackdrop] = useState();

  const { user_id, super_admin_id } = decodeToken();

  const formik = useFormik({
    initialValues: {
      full_name: "",
      password: "",
      mobile_no: "",
      email: "",
      role: "Mentor",
      gender: "",
      avatar_url: "",
      pan_no: "",
      addhar_no: "",
      address: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setBackdrop(true);
      values.country = countryId;
      values.state = stateId;
      values.city = cityId;
      values.id = rowData.id;
      values.role = super_admin_id ? "superAdmin" : "Mentor";
      try {
        if (avtarImg) {
          const url = await uploadImg(file, "users");
          console.log(url, "url");
          values.avatar_url = url;
        }
        let { status } = await UserService.updateUser(values);
        getUserInfo();
        toast.success("User updated successfully");
        setBackdrop(false);
      } catch (err) {
        toast.error("Something went wrong!");
        setBackdrop(false);
      }
    },
  });
  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });

    getUserInfo();
  }, []);

  const onChangeCountry = (e) => {
    let id = e.target.value;
    setCountryId(e.target.value);
    GetState(parseInt(id)).then((result) => {
      setStateList(result);
    });
  };

  const getUserInfo = async () => {
    try {      
      const { data } = await UserService.fetchUser({ user_id, super_admin_id });
      setRowData(data.data[0]);
    } catch (err) {
      setRowData([]);
      console.log(err);
    }
  };

  useEffect(() => {
    if (rowData?.email) {
      formik.setFieldValue("full_name", rowData.full_name);
      formik.setFieldValue("email", rowData.email);
      formik.setFieldValue("mobile_no", rowData.mobile_no);
      setCountryId(+rowData.country);
      GetState(parseInt(+rowData.country)).then((result) => {
        setStateList(result);
      });
      GetCity(+rowData.country, +rowData.state).then((result) => {
        setCityList(result);
      });
      setStateId(+rowData.state);
      setCityId(+rowData.city);
      formik.setFieldValue("pincode", rowData.pincode);
      formik.setFieldValue("addhar_no", rowData.addhar_no);
      formik.setFieldValue("pan_no", rowData.pan_no);
      formik.setFieldValue("gender", rowData.gender);
      formik.setFieldValue("password", rowData.password);
      formik.setFieldValue("address", rowData.address);
    }
  }, [rowData]);

  const onImgChange = async (file) => {
    if (file) {
      setAvtarImg("");
      setFile("");
      setFile(file);
      const url = await convertBase64(file, "users");
      setAvtarImg(url);
    }
  };

  const onImgDelete = async (row) => {
    let { avatar_url } = row;
    if (avatar_url) {
      let splitPublicId = avatar_url?.split("/").pop();
      let publicId = splitPublicId.split(".")[0];
      console.log(publicId);
      try {
        let result = await deleteImg(publicId, "users");
        if (result) {
          row.avatar_url = "";
          const { status } = await UserService.updateUser(row);
          if (status) {
            getUserInfo();
            toast.success("User photo deleted successfully");
          }
        }
      } catch (err) {
        toast.error("Someting went wrong!");
      }
    }
  };

  return (
    <div className="container">
      <div className="card col-md-12 my-3 mx-auto">
        <div className="card-body p-5">
          <h4>Update Profile</h4>
          <div className="row mt-4">
            <div className="col-md-4">
              <div className="profile-img">
                <input
                  style={{ display: "none" }}
                  id="avtarImgId"
                  type="file"
                  onChange={(e) => onImgChange(e.target.files[0])}
                />
                {avtarImg || rowData.avatar_url ? (
                  <img
                    className="img-fluid"
                    src={avtarImg || rowData.avatar_url}
                  />
                ) : (
                  <i className="fa fa-user"></i>
                )}
              </div>
              {rowData.avatar_url ? (
                <label
                  className="upload-btn"
                  onClick={() => onImgDelete(rowData)}
                  variant="outlined"
                  fullWidth
                >
                  Delete Avtar
                </label>
              ) : (
                <label
                  className="upload-btn"
                  htmlFor="avtarImgId"
                  onClick={() => setFile(avtarImg)}
                  variant="outlined"
                  fullWidth
                >
                  Choose Avtar
                </label>
              )}
            </div>
            <div className="col-md-8">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <div class="form-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Fullname"
                        name="full_name"
                        value={formik.values.full_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <small className="text-danger">
                        {formik.touched.full_name && formik.errors.full_name}
                      </small>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div class="form-group mb-3">
                      <input
                        class="form-control"
                        placeholder="Email"
                        name="email"
                        disabled
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <small className="text-danger">
                        {formik.touched.email && formik.errors.email}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div class="form-group mb-3">
                      <input
                        class="form-control"
                        placeholder="Mobile"
                        name="mobile_no"
                        value={formik.values.mobile_no}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <small className="text-danger">
                        {formik.touched.mobile_no && formik.errors.mobile_no}
                      </small>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div class="form-group mb-3">
                      <select
                        className="form-control"
                        name="gender"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div class="form-group mb-3">
                      <input
                        class="form-control"
                        placeholder="Pancard Number"
                        name="pan_no"
                        value={formik.values.pan_no}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <small className="text-danger">
                        {formik.touched.pan_no && formik.errors.pan_no}
                      </small>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div class="form-group mb-3">
                      <input
                        class="form-control"
                        placeholder="Aadhaar Number"
                        name="addhar_no"
                        value={formik.values.addhar_no}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <small className="text-danger">
                        {formik.touched.addhar_no && formik.errors.addhar_no}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 mb-3">
                    <select
                      className="form-control"
                      name="country"
                      value={countryId}
                      onChange={onChangeCountry}
                    >
                      <option value="">Select Country</option>
                      {countriesList?.map((item, index) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-6  mb-3">
                    <select
                      className="form-control"
                      name="state"
                      value={stateId}
                      onChange={(e) => {
                        setStateId(e.target.value);
                        GetCity(+countryId, +e.target.value).then((result) => {
                          setCityList(result);
                        });
                      }}
                    >
                      <option value="">Select State</option>
                      {stateList?.map((item, index) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6  mb-3">
                    <select
                      className="form-control"
                      name="city"
                      value={cityId}
                      onChange={(e) => {
                        setCityId(e.target.value);
                      }}
                    >
                      <option value="">Select City</option>
                      {cityList?.map((item, index) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-6">
                    <div class="form-group mb-3">
                      <input
                        class="form-control"
                        placeholder="Pincode"
                        name="pincode"
                        value={formik.values.pincode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <small className="text-danger">
                        {formik.touched.pincode && formik.errors.pincode}
                      </small>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div class="form-group mb-3">
                      <input
                        class="form-control"
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <small className="text-danger">
                        {formik.touched.password && formik.errors.password}
                      </small>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <textarea
                      placeholder="Address"
                      class="form-control"
                      name="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></textarea>
                  </div>
                </div>

                <button type="submit" class="btn btn-primary mt-3 w-100">
                  Update Profile
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {backdrop && <Backdrop />}
    </div>
  );
};

export default MentorProfile;
