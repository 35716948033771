import PaymentService from "../../services/payment";
import crypto from "crypto-js";
import { toast } from "react-toastify";
import moment from "moment";

const onRazorpay = async ( sponsorData) => {
    try {
      const res = await PaymentService.razorPayment({
        amount: sponsorData.amount,
      });
      let order_id = res.data.id;
      let currency = res.data.currency;
      let amount = res.data.amount;
      let today = new Date();
      
      var options = {
        key: process.env.REACT_APP_RAZOR_KEY_ID,
        amount: amount,
        currency: currency,
        name: "Shaagrid Foundation",
        description: "Kid donation for study",
        order_id: order_id,
        handler: function (response) {
          let razorpay_signature = crypto
            .HmacSHA256(
              `${order_id}|${response.razorpay_payment_id}`,
              process.env.REACT_APP_RAZOR_KEY_SECRET
            )
            .toString();
          let {
            amount,
            kid_id,
            mentor_id ,
            is_anonymous,
            category_id,
            for_months,
            is_active,
            anonymous_mobile_no
          } = sponsorData;
          
          let mentoring_left_date = new Date(new Date().setDate(today.getDate() + 30 * for_months));

          let verifyPaymentObj = {
            razorpay_order_id: order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature,
            amount,
            kid_id,
            mentor_id,
            is_anonymous,
            category_id,
            for_months,
            is_active,
            anonymous_mobile_no,
            mentoring_left_date,
            status:"Paid Online",
            receipt_no:Math.floor(100000000 + Math.random() * 900000000),
            payment_date:new Date().toLocaleDateString(),
            transaction_id:response.razorpay_payment_id,
            email: sponsorData?.email,
            mentor_name: sponsorData?.mentor_name,
            address:sponsorData?.address,
            pan_number:sponsorData?.pan_number,  
          };
          PaymentService.verifyPayment(verifyPaymentObj)
            .then((res) => {
              window.location.href = `/success/${response.razorpay_payment_id}`;
              toast.success("Payment Successfully")
            })
            .catch((err) => {
              window.location.href = '/cancel';
              toast.success("Payment Failed")
            });
          if (res) {
          } else {
          }
        },
        prefill: {
          //Here we are prefilling random contact
          contact: sponsorData?.phone || sponsorData?.anonymous_mobile_no,
          //name and email id, so while checkout
          name: sponsorData?.mentor_name,
          email: sponsorData?.email,
        },
        notes: {
          // description: "Testing",
        },
        theme: {
          color: "#fdc513",
        },
      };
      var razorpayObject = new window.Razorpay(options);
      console.log(razorpayObject);
      razorpayObject.open();

      razorpayObject.on("payment.failed", function (response) {
        console.log(response);
        alert("This step of Payment Failed");
      });
    } catch (err) {
      console.log(err);
    }
  
};

export default onRazorpay;