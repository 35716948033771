import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import decodeToken from "../utils/decodeToken";
import { toast } from "react-toastify";
import { IoClose, IoMenu } from "react-icons/io5";
import logo from "../logo.png";
import "./Navbar.css";
import Notification from "../pages/notification/Notification";

const NewNavBar = () => {
  const [showMenu, setShowMenu] = useState(false);
  let { user_id, super_admin_id, full_name, avatar_url } = decodeToken();
  let [toggleLogin, setToggleLogin] = useState(false);
  let [toggleNotification, setToggleNotification] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 1150) {
      setShowMenu(false);
    }
  };
  const onLogout = () => {
    localStorage.removeItem("loginToken");
    toast.success("Logout successfully");
    navigate("/");
    closeMenuOnMobile();
  };
  return (
    <header className="header nav">
      <nav className="nav container">
        <NavLink to="http://shaagird.org/" className="nav__logo">
          <img src={logo} alt="Logo" className="logo" />
        </NavLink>

        <div
          className={`nav__menu ${showMenu ? "show-menu" : ""}`}
          id="nav-menu"
        >
          <ul className="nav__list">
            <li className="nav__item">
              <NavLink
                to={"/"}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                onClick={closeMenuOnMobile}
              >
                Sponsor Kids
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink
                to={"/sponsored-kids"}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                onClick={closeMenuOnMobile}
              >
                Sponsored Kids
              </NavLink>
            </li>
            {user_id || super_admin_id ? (
              <>
                {/* <span
                    onClick={() => setToggleNotification(!toggleNotification)}
                    className="position-relative"
                  >
                    <span className="me-2 cursor-pointer">
                      <i
                        className="fa fa-bell-o text-warning"
                        aria-hidden="true"
                      ></i>
                    </span>
                    {toggleNotification && <Notification />}
                  </span> */}

                <span
                  onClick={() => setToggleLogin(!toggleLogin)}
                  className="position-relative nav__item"
                >
                  <span className="me-2 cursor-pointer">
                    {avatar_url ? (
                      <img width={20} src={avatar_url} alt="avatar" />
                    ) : (
                      <i className="fa fa-user-circle text-warning"></i>
                    )}{" "}
                    <span className="d-sm-inline">{full_name}</span>{" "}
                    <i className="fa fa-caret-down"></i>
                  </span>
                  {toggleLogin && (
                    <div className="login-option">
                      <ul>
                        {user_id ? (
                          <li>
                            <NavLink
                              to={"/mentor-profile"}
                              onClick={closeMenuOnMobile}
                              aria-disabled
                            >
                              <i className="fa fa-cog" aria-hidden="true"></i>
                              Profile
                            </NavLink>
                          </li>
                        ) : null}
                        <li>
                          <NavLink
                            to={"/payment-history"}
                            onClick={closeMenuOnMobile}
                          >
                            <i className="fa fa-money" aria-hidden="true"></i>
                            Payments
                          </NavLink>
                        </li>
                        <li onClick={onLogout}>
                          <i className="fa fa-power-off"></i> Logout
                        </li>
                      </ul>
                    </div>
                  )}
                </span>
              </>
            ) : (
              <NavLink
                to={"/login"}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                onClick={closeMenuOnMobile}
              >
                Login
              </NavLink>
            )}
            <li className="nav__item">
              <NavLink
                to="https://shaagird.org/donate/"
                className="donate-now btn-primary"
              >
                Donate Now
                <i class="fa fa-heart-o d-inline" aria-hidden="true"></i>
              </NavLink>
            </li>
          </ul>

          <div className="nav__close" id="nav-close" onClick={toggleMenu}>
            <IoClose />
          </div>
        </div>

        <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
          <IoMenu />
        </div>
      </nav>
    </header>
  );
};

export default NewNavBar;
