import React from "react";
import NewNavBar from "./NewNavBar";

const Header = () => {
  return (
    <header>
      <div className="container">
        {/* <div className="row">
          <div className="col-2">
            <a href="https://shaagird.org/" target="_blank" rel="noreferrer">
              <img className="logo" src="../logo.png" alt="logo" />
            </a>
          </div>
          <div className="col d-flex justify-content-end"> */}
        <NewNavBar />
        {/* </div>
        </div>*/}
      </div>
    </header>
  );
};

export default Header;
